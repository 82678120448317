<template>
  <b-modal
    id="document-viewer-modal"
    :title="title"
    size="xl"
    hide-header
    hide-footer
    centered
    @hidden="handleHidden"
  >
    <div class="text-center">
      <img v-if="isImageMimeType" :src="url" :alt="title" />
      <div v-else>
        <iframe
          ref="gdoc-viewer"
          :src="googleDocViewerUrl"
          class="gdoc-viewer w-100 h-100"
          style="border:none;"
          @load="handleIframeLoad"
        />
        <div class="loading-overlay" v-if="!iframe.loading.done">
          <div v-if="failedToLoadIframeDocument" class="text-white">
            <p class="mb-2">
              <translate
                >Something went wrong while trying to display the file. Try
                opening it in another browser tab.</translate
              >
            </p>
            <a class="btn btn-primary" :href="url" target="_blank"
              ><translate>Open document</translate></a
            >
          </div>
          <pulse-loader v-else color="#9176cc" size="15px" />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: { PulseLoader },
  props: {
    url: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    mimeType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      iframe: {
        loading: {
          interval: null,
          attempt: 0,
          maxAttempt: 3,
          done: false
        }
      }
    };
  },
  computed: {
    ...mapGetters("me", ["lg"]),
    isImageMimeType() {
      return ["image/jpeg", "image/png"].includes(this.mimeType);
    },
    googleDocViewerUrl() {
      const encodedUrl = encodeURIComponent(this.url);
      return `https://docs.google.com/viewer?url=${encodedUrl}&embedded=true&hl=${this.lg}`;
    },
    failedToLoadIframeDocument() {
      return (
        this.iframe.loading.attempt === this.iframe.loading.maxAttempt &&
        !this.iframe.loading.done
      );
    }
  },
  methods: {
    updateIframeSrc() {
      if (this.iframe.loading.attempt < this.iframe.loading.maxAttempt) {
        this.iframe.loading.attempt++;
        this.$refs[
          "gdoc-viewer"
        ].src = `${this.googleDocViewerUrl}&=attempt=${this.iframe.loading.attempt}`;
      } else {
        clearInterval(this.iframe.loading.interval);
      }
    },
    handleIframeLoad() {
      this.iframe.loading.done = true;
      clearInterval(this.iframe.loading.interval);
    },
    handleHidden() {
      if (this.iframe.loading.interval) {
        this.resetIframeLoading();
      }
    },
    resetIframeLoading() {
      this.iframe.loading.attempt = 0;
      this.iframe.loading.done = false;
      clearInterval(this.iframe.loading.interval);
    }
  },
  watch: {
    url() {
      if (!this.isImageMimeType) {
        this.resetIframeLoading();
        this.iframe.loading.interval = setInterval(
          this.updateIframeSrc,
          1000 * 3
        );
      }
    }
  }
};
</script>
