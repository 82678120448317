import { translate } from "vue-gettext";
const { gettext: $gettext } = translate;

export const applicationStatus = {
  get new() {
    return $gettext("new");
  },
  get rejected() {
    return $gettext("rejected");
  },
  get in_review() {
    return $gettext("in_review");
  },
  get interview_1() {
    return $gettext("interview_1");
  },
  get interview_2() {
    return $gettext("interview_2");
  },
  get offer_sent() {
    return $gettext("offer_sent");
  },
  get hired() {
    return $gettext("hired");
  }
};
