var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[(_vm.showBlankState)?_c('EmptyState',{attrs:{"icon":"jbsmd-candidate","message":_vm.$gettext('You don\'t have any applications yet'),"show-button":false}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showBlankState),expression:"!showBlankState"}],staticClass:"col-md-12"},[_c('DocumentViewerModal',{attrs:{"url":_vm.documentViewed.url,"title":_vm.documentViewed.title,"mime-type":_vm.documentViewed.mimeType}}),_c('ContentHeader',{attrs:{"title":_vm.$gettext('Applications')}}),_c('AdvancedTable',{attrs:{"store":"applications","fields":_vm.fields,"advance-filter-enabled":true},scopedSlots:_vm._u([{key:"cell(full_name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
            name: 'candidate-details',
            params: { candidateId: data.item.candidate_id }
          }}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(offer_title)",fn:function(ref){
          var item = ref.item;
return [(item.offer_is_deleted)?_c('span',[_vm._v(_vm._s(item.offer_title)+" [DELETED]")]):_c('router-link',{attrs:{"to":{
            name: 'application-candidate',
            params: {
              offerId: item.offer_id,
              applicationId: item.application_id
            }
          }}},[_vm._v(_vm._s(item.offer_title))])]}},{key:"cell(company_name)",fn:function(ref){
          var item = ref.item;
return [(item.company_is_deleted)?_c('span',[_vm._v(_vm._s(item.company_name)+" [DELETED]")]):_c('router-link',{attrs:{"to":{
            name: 'company-details',
            params: { companyId: item.company_id }
          }}},[_vm._v(_vm._s(item.company_name))])]}},{key:"cell(status)",fn:function(data){return [_c('b-form-select',{attrs:{"options":_vm.applicationStatusOptions},on:{"input":function (status) { return _vm.updateApplicationStatus({
                id: data.item.application_id,
                status: status
              }); }},model:{value:(data.item.status),callback:function ($$v) {_vm.$set(data.item, "status", $$v)},expression:"data.item.status"}})]}},{key:"cell(created_at)",fn:function(data){return [_c('FormattedDate',{attrs:{"date":data.item.created_at,"format":{ dateStyle: 'medium' }}})]}},{key:"cell(documents)",fn:function(data){return _vm._l((data.item.documents),function(document){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:document.id,staticClass:"btn btn-outline-primary btn-icon btn-sm p-0 mr-2",attrs:{"title":_vm.$ApplicationDocumentType.from(document.pivot.type).label},on:{"click":function($event){return _vm.handleViewDocument(document)}}},[_c('i',{staticClass:"fa fa-copy"})])})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }