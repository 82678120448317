<template>
  <div class="row">
    <EmptyState
      v-if="showBlankState"
      icon="jbsmd-candidate"
      :message="$gettext('You don\'t have any applications yet')"
      :show-button="false"
    ></EmptyState>
    <div v-show="!showBlankState" class="col-md-12">
      <DocumentViewerModal
        :url="documentViewed.url"
        :title="documentViewed.title"
        :mime-type="documentViewed.mimeType"
      />
      <ContentHeader :title="$gettext('Applications')" />
      <AdvancedTable
        store="applications"
        :fields="fields"
        :advance-filter-enabled="true"
      >
        <template #cell(full_name)="data">
          <router-link
            :to="{
              name: 'candidate-details',
              params: { candidateId: data.item.candidate_id }
            }"
          >
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(offer_title)="{item}">
          <span v-if="item.offer_is_deleted"
            >{{ item.offer_title }} [DELETED]</span
          >
          <router-link
            v-else
            :to="{
              name: 'application-candidate',
              params: {
                offerId: item.offer_id,
                applicationId: item.application_id
              }
            }"
            >{{ item.offer_title }}</router-link
          >
        </template>
        <template #cell(company_name)="{item}">
          <span v-if="item.company_is_deleted"
            >{{ item.company_name }} [DELETED]</span
          >
          <router-link
            v-else
            :to="{
              name: 'company-details',
              params: { companyId: item.company_id }
            }"
            >{{ item.company_name }}</router-link
          >
        </template>
        <template #cell(status)="data">
          <b-form-select
            v-model="data.item.status"
            :options="applicationStatusOptions"
            @input="
              status =>
                updateApplicationStatus({
                  id: data.item.application_id,
                  status
                })
            "
          ></b-form-select>
        </template>
        <template #cell(created_at)="data"
          ><FormattedDate
            :date="data.item.created_at"
            :format="{ dateStyle: 'medium' }"
        /></template>
        <template #cell(documents)="data">
          <b-button
            v-for="document in data.item.documents"
            :key="document.id"
            @click="handleViewDocument(document)"
            class="btn btn-outline-primary btn-icon btn-sm p-0 mr-2"
            v-b-tooltip.hover
            :title="$ApplicationDocumentType.from(document.pivot.type).label"
            ><i class="fa fa-copy"></i
          ></b-button>
        </template>
      </AdvancedTable>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState";
import ContentHeader from "@/components/ContentHeader";
import AdvancedTable from "@/components/AdvancedTable";
import FormattedDate from "@/components/FormattedDate";
import { VBTooltip } from "bootstrap-vue";
import { applicationStatus } from "@/enums/applicationStatus";
import DocumentViewerModal from "@/components/Document/ViewerModal.vue";
Vue.directive("b-tooltip", VBTooltip);

export default {
  components: {
    DocumentViewerModal,
    EmptyState,
    ContentHeader,
    AdvancedTable,
    FormattedDate
  },
  data: () => ({
    documentViewed: {
      url: "",
      title: "",
      mimeType: ""
    }
  }),
  computed: {
    ...mapGetters("applications", ["showBlankState"]),
    ...mapGetters("app", ["isFetching"]),
    fields() {
      return [
        {
          key: "full_name",
          sortable: true,
          label: this.$gettext("Name")
        },
        { key: "email", sortable: true, label: this.$gettext("Email") },
        {
          key: "offer_title",
          sortable: true,
          label: this.$gettext("Offer")
        },
        {
          key: "company_name",
          sortable: true,
          label: this.$gettext("Company")
        },
        {
          key: "source",
          sortable: true,
          label: this.$gettext("Source")
        },
        { key: "status", sortable: true },
        { key: "location.address", label: this.$gettext("City") },
        {
          key: "created_at",
          sortable: true,
          label: this.$gettext("Date")
        },
        { key: "documents", label: this.$gettext("Documents") }
      ];
    },
    applicationStatusOptions() {
      return applicationStatus;
    }
  },
  methods: {
    ...mapActions("applications", ["updateApplicationStatus"]),
    handleView({ item }) {
      this.$router.push({
        name: "application-candidate",
        params: {
          offerId: item.offer_id,
          applicationId: item.application_id
        }
      });
    },
    handleViewDocument(document) {
      this.documentViewed = {
        url: document.cdn,
        title: this.$ApplicationDocumentType.from(document.pivot.type).label,
        mimeType: document.mime_type
      };
      this.$bvModal.show("document-viewer-modal");
    }
  }
};
</script>
